body {
  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: white;
  width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 

}
.h3{
  font-size: 4vh !important;
}
@media (max-width: 768px) {
  .hideOverflowOnMobile {
  overflow: hidden;
  }
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbaritem {
  justify-content: space-evenly;

  margin-top: 3vh;
  margin-right: 3vw;
  font-size: 150%;
  color: rgb(255, 255, 255);
  
}
.btn-large {
  border-radius: 5px !important; 
  margin: 10px;
}
.btn-large:hover{
  font-size: larger;
  font-weight: bolder;
}
.scope {
  border-radius: 10px;
}
.bold{
  font-weight: bolder;
  outline-color: white;
  outline-width: 2px;
}
/* .bg {
  position: absolute;
  left: -5%;
  width: 100%;
  z-index: -4;
} */

.sliderbg{
  
  background-color: rgba(rgb(151, 0, 0), 0.2);
  border-radius: 5px;
}
.card{
  border-radius: 20px;
}

/* .spacer{
  min-height: 100vh !important;
  position: relative !important;
} */
.home{
  position: absolute;
  margin-top: -40vh;
  z-index: 3;
}
.buttoms{
  z-index: 3 !important;
}
.Slider{
  z-index: -2;
  top: -2vh;
}

.awaria{
  z-index: 4;
}
.portret{
  border-radius: 30px;
}
.omnie{
  font-size: 21px;
}
nav {
  height: 80px;
  line-height: 80px;
}

nav i, nav [class^="mdi-"], nav [class*="mdi-"], nav i.material-icons {
  height: 80px;
  line-height: 80px;
}

nav .button-collapse i {
  height: 80px;
  line-height: 80px;
}

nav .brand-logo { 
  font-size: 1.6rem; 
}

@media only screen and (min-width: 601px){
  nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
    height: 80px;
  line-height: 80px;
  }
}